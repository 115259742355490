import React from 'react'
import { Link } from 'gatsby'

import { Box, Button, FlexCol, H2, Text } from 'src/components'

const NotFoundPage = () => (
  <>
    <Box px={[3, null, null, 4]} py={[4, 5]} bg="bg.default">
      <Box maxWidth={1280} mx="auto">
        <FlexCol justifyContent="center" alignItems="center">
          <H2 children={`404: Page not found`} mb="0.5em" textAlign="center" />
          <Text
            children={`You may have gotten lost or followed a dead link.`}
            mb="2em"
            textAlign="center"
          />
          <Box>
            <Button children={`Back to Home`} as={Link} to="/" />
          </Box>
        </FlexCol>
      </Box>
    </Box>
  </>
)

export default NotFoundPage
